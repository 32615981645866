@font-face {
  font-family: 'bold';
  src: local('Neris-Bold'), url(./assets/font/Neris/Neris-SemiBold.otf) format('truetype');
}
@font-face {
  font-family: 'light';
  src: local('Neris-Light'), url(./assets/font/Neris/Neris-Light.otf) format('truetype');
}
@font-face {
  font-family: 'medium';
  src: local('Neris-Medium'), url(./assets/font/Neris/Neris-Black.otf) format('truetype');
}
@font-face {
  font-family: 'regular';
  src: local('Neris-Regular'), url(./assets/font/Neris/Neris-Light.otf) format('truetype');
}
@font-face {
  font-family: 'thin';
  src: local('Neris-Thin'), url(./assets/font/Neris/Neris-Thin.otf) format('truetype');
}
